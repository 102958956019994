import { useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import { SchedulerAppointmentHistoryHelper } from '@/helpers';
import { AppointmentAuditActionToDialogTitle, Atria } from '@/@types';

type AppointmentHistoryProps = {
  onReturn: () => void;
  appointmentId?: number;
};

const skeletonAnimation = 'wave';

export const AppointmentHistory = ({ appointmentId, onReturn }: AppointmentHistoryProps) => {
  const { toast } = useToastContext();
  const [appointmentHistory, setAppointmentHistory] =
    useState<Atria.FindAppointmentHistory.Response>([]);
  const [skeletonHidden, setSkeletonHidden] = useState(true);

  const formattedAppointmentHistory = useMemo(
    () => SchedulerAppointmentHistoryHelper.getFormattedAppointmentAudits(appointmentHistory),
    [appointmentHistory]
  );

  const handleGetAppointmentHistory = async (selectedAppointmentId: number) => {
    try {
      const { data } = await appointmentsService.findAppointmentHistory(selectedAppointmentId);

      setAppointmentHistory(data);
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to load history',
        life: 3000,
      });
    } finally {
      setSkeletonHidden(false);
    }
  };

  useEffect(() => {
    if (!appointmentId) return;

    handleGetAppointmentHistory(appointmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  return (
    <main className='overflow-y-auto max-w-[calc(100vh-150px)]'>
      {!skeletonHidden ? (
        <>
          <Button
            label='Return to details'
            text
            icon='pi pi-arrow-left'
            onClick={onReturn}
            className='my-2'
          />

          {formattedAppointmentHistory.map((appointmentAudit) => (
            <div
              className='w-full min-h-[124px] p-5 bg-stone-100 rounded-xl flex-col justify-start items-start gap-1 inline-flex bg-[#F4F1EE] mb-4'
              key={appointmentAudit.id}
            >
              <div className="text-[#3C4E3D] text-lg font-bold font-['Scto Grotesk A'] leading-7">
                {AppointmentAuditActionToDialogTitle[appointmentAudit.action] ?? ''}
              </div>
              <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal">
                {appointmentAudit.formattedDate}
              </div>
              <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal">
                User: {appointmentAudit.authorEmail}
              </div>
              {!!appointmentAudit.changesMade && (
                <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal mt-8">
                  Changes made: {appointmentAudit.changesMade}
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className='flex flex-col space-y-4 overflow-y-auto flex-1'>
          <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
          <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
          <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
          <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
          <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
        </div>
      )}
    </main>
  );
};
