import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Sidebar } from 'primereact/sidebar';
import { loader } from 'react-global-loader';

import { AppointmentWithDate, Atria, PatientVisit, Provider } from '@/@types';
import { Permission, useAuthContext, useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';

type PatientVisitDetailsProps = {
  visible: boolean;
  onHide: () => void;
  handleOnEditAppointments: (appointments: AppointmentWithDate[]) => void;
  patientVisit: PatientVisit;
};

export const PatientVisitDetails = ({
  visible,
  onHide,
  handleOnEditAppointments,
  patientVisit,
}: PatientVisitDetailsProps) => {
  const { hasPermission } = useAuthContext();
  const { toast } = useToastContext();
  const [selectedAppointments, setSelectedAppointments] = useState<number[]>([]);
  const [selectedAllAppointments, setSelectedAllAppointments] = useState(false);
  const visitAppointmentsProvidersWithoutLiaison = useMemo(() => {
    const appointmentsProviders = new Map<number, Provider[]>();

    patientVisit.appointments.forEach((appointment) => {
      appointment.providers?.forEach((provider) => {
        if (provider.type !== 'liaison') {
          appointmentsProviders.has(appointment.id)
            ? appointmentsProviders.set(appointment.id, [
                ...appointmentsProviders.get(appointment.id)!,
                provider,
              ])
            : appointmentsProviders.set(appointment.id, [provider]);
        }
      });
    });

    return appointmentsProviders;
  }, [patientVisit.appointments]);

  const canEdit = useMemo(
    () => selectedAppointments.length > 0 && hasPermission(Permission.EDIT_APPOINTMENT),
    [hasPermission, selectedAppointments.length]
  );

  const formattedStartAndEndTime = (event: AppointmentWithDate) => {
    const start = DateTime.fromJSDate(event.date!);
    const end = DateTime.fromJSDate(event.end!);
    const formattedStart =
      start.toFormat('mm') === '00' ? start.toFormat('h a') : start.toFormat('h:mm a');
    const formattedEnd = end.toFormat('mm') === '00' ? end.toFormat('h a') : end.toFormat('h:mm a');

    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleOnSelectAppointments = useCallback((e: CheckboxChangeEvent) => {
    if (e.checked) {
      return setSelectedAppointments((prev) => [...prev, e.value]);
    }

    setSelectedAppointments((prev) => prev.filter((id) => id !== e.value));
  }, []);

  const handleOnSelectAllAppointments = useCallback(
    (e: CheckboxChangeEvent) => {
      setSelectedAllAppointments(e.checked!);

      if (e.checked) {
        return setSelectedAppointments(patientVisit.appointments.map(({ id }) => id));
      }

      setSelectedAppointments([]);
    },
    [patientVisit.appointments]
  );

  const onConfirmAppointments = useCallback(async () => {
    const appointmentsToConfirm = patientVisit.appointments.filter(({ id }) =>
      selectedAppointments.includes(id)
    );

    try {
      loader.show();

      const atria: Atria.UpdateMultipleAppointments.Body['atria'] = appointmentsToConfirm
        .filter((a) => a.atriaAppointment)
        .map((appt) => ({
          ...appt,
          date: appt.date.toJSON(),
          roomsIds: appt.rooms?.map(({ id }) => id) || [],
          confirmed: 'FULLY_CONFIRMED',
        }));

      const athena: Atria.UpdateMultipleAppointments.Body['athena'] = appointmentsToConfirm
        .filter((a) => !a.atriaAppointment)
        .map((appt) => ({
          ...appt,
          roomsIds: appt.rooms?.map(({ id }) => id) || [],
          confirmed: true,
        }));

      await appointmentsService.updateMultipleAppointments({ atria, athena });

      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Appointments confirmed successfully',
        life: 3000,
      });
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to confirm appointments',
        life: 3000,
      });
    } finally {
      loader.hide();
    }
  }, [patientVisit.appointments, selectedAppointments, toast]);

  const onEditAppointments = useCallback(() => {
    const appointmentsToEdit = patientVisit.appointments.filter(({ id }) =>
      selectedAppointments.includes(id)
    );

    handleOnEditAppointments(appointmentsToEdit);
  }, [handleOnEditAppointments, patientVisit.appointments, selectedAppointments]);

  useEffect(
    () => () => {
      setSelectedAllAppointments(false);
      setSelectedAppointments([]);
    },
    [visible]
  );

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      blockScroll={true}
      className='w-[500px]'
    >
      {patientVisit && (
        <>
          <header className='border-b-[1px] bottom-[var(--gray-1)] flex items-center justify-between'>
            <h1 className='text-[color:var(--moss)] text-[length:var(--font-lg)] font-bold'>
              {patientVisit.title}
            </h1>
            <Button icon='pi pi-times' rounded text aria-label='close' onClick={onHide} />
          </header>
          <main className='mt-[var(--space-regular)]'>
            {patientVisit.liaison && (
              <div className='flex'>
                <p className='font-bold mr-[var(--space-xxs)]'>Liaison:</p>
                <p>{patientVisit.liaison.name}</p>
              </div>
            )}
            {patientVisit.cmo && (
              <div className='flex'>
                <p className='font-bold mr-[var(--space-xxs)]'>CMO:</p>
                <p>{patientVisit.cmo}</p>
              </div>
            )}
            {patientVisit.appointments.length > 0 && (
              <>
                <div className='mt-4 px-3'>
                  <Checkbox
                    inputId='select-all'
                    name='select-all'
                    value={selectedAllAppointments}
                    onChange={handleOnSelectAllAppointments}
                    checked={selectedAllAppointments}
                  />
                  <label htmlFor='select-all' className='ml-3 text-sm'>
                    Select all
                  </label>
                </div>

                {patientVisit.appointments?.map((appointment) => (
                  <div
                    key={appointment.id}
                    className='flex items-center text-[color:var(--gray-5] text-[length:var(--font-regular)] mt-4 border-[1px] rounded-[10px] p-3'
                  >
                    <Checkbox
                      inputId={`appt-${appointment.id}`}
                      name={appointment.title}
                      value={appointment.id}
                      onChange={handleOnSelectAppointments}
                      checked={selectedAppointments.includes(appointment.id)}
                    />

                    <div className='flex flex-col items-start ml-6'>
                      <p>
                        <b>{appointment.title}</b>
                      </p>
                      <p>{formattedStartAndEndTime(appointment)}</p>
                      <p>{appointment.providerName}</p>
                      {appointment.rooms && appointment.rooms.length > 0 && (
                        <p>
                          {appointment.rooms.map((room, index) =>
                            index === 0 ? room.label : `, ${room.label}`
                          )}
                        </p>
                      )}
                      {visitAppointmentsProvidersWithoutLiaison.has(appointment.id) && (
                        <p>
                          {visitAppointmentsProvidersWithoutLiaison
                            .get(appointment.id)!
                            .map((provider, index) =>
                              index === 0 ? provider.name : `, ${provider.name}`
                            )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}

                <div className='flex flex-col mt-8'>
                  <Button
                    label='Confirm'
                    className='my-2'
                    disabled={!canEdit}
                    onClick={onConfirmAppointments}
                  />
                  <Button
                    label='Edit'
                    className='my-2'
                    disabled={!canEdit}
                    onClick={onEditAppointments}
                    outlined
                  />
                </div>
              </>
            )}
          </main>
        </>
      )}
    </Sidebar>
  );
};
