import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import { Permission, useAuthContext, useToastContext } from '@/contexts';
import { ConfirmDeleteDialog } from '@/components';
import { appointmentsService } from '@/services';
import { DetailsContent } from './DetailsContent';
import { AppointmentHistory } from './AppointmentHistory';
import { AppointmentWithResourcesList } from '@/@types';

type AppointmentDetailsDrawerProps = {
  appointment?: AppointmentWithResourcesList | undefined;
  onDeleteAppointment: (appointment: AppointmentWithResourcesList) => void;
  onDuplicateAppointment: (appointment: AppointmentWithResourcesList) => void;
  onEditAppointment: (appointment: AppointmentWithResourcesList) => void;
  onHide: () => void;
  visible: boolean;
};

export const AppointmentDetailsDrawer = ({
  appointment,
  onDeleteAppointment,
  onDuplicateAppointment,
  onEditAppointment,
  onHide,
  visible,
}: AppointmentDetailsDrawerProps) => {
  const { toast } = useToastContext();
  const { hasPermission } = useAuthContext();

  const [showConfirmConferenceLink, setShowConfirmConferenceLink] = useState(false);
  const [showAppointmentHistory, setShowAppointmentHistory] = useState(false);

  const updatedByLabel = useMemo(
    () =>
      appointment &&
      `Last edited by ${
        appointment.updatedBy
      } on ${appointment.updatedAt.toLocaleDateString()} ${appointment.updatedAt.toLocaleTimeString()}.`,
    [appointment]
  );

  const deleteZoomLink = useCallback(async () => {
    try {
      if (!appointment) return;
      await appointmentsService.deleteConferenceLinkFromAppointment(appointment.id);

      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Zoom link deleted successfully',
        life: 3000,
      });
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to delete zoom link',
        life: 3000,
      });
    } finally {
      onHide();
    }
  }, [appointment, onHide, toast]);

  useEffect(() => {
    if (!visible) {
      setShowAppointmentHistory(false);
    }
  }, [visible]);

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      className='w-[500px]'
      blockScroll={true}
    >
      {appointment && (
        <>
          <header className='pb-4 border-b-[1px] border-gray-1 flex items-center justify-between'>
            <h1 className='text-moss text-2xl font-bold'>{appointment.title}</h1>
            <Button icon='pi pi-times' rounded text aria-label='close' onClick={onHide} />
          </header>

          {!showAppointmentHistory ? (
            <>
              <DetailsContent
                appointment={appointment}
                onShowConferenceLink={() => setShowConfirmConferenceLink(true)}
              />
              <footer className='w-full mt-6 flex flex-col'>
                <Button
                  label='Edit'
                  icon='pi pi-pencil'
                  iconPos='right'
                  size='small'
                  className='bg-moss border-moss text-white rounded-[10px]'
                  onClick={() => onEditAppointment(appointment)}
                  disabled={!hasPermission(Permission.EDIT_APPOINTMENT)}
                />
                {hasPermission(Permission.DUPLICATE_APPOINTMENT) && (
                  <Button
                    label='Duplicate'
                    icon='pi pi-copy'
                    iconPos='right'
                    size='small'
                    outlined
                    className='mt-2 bg-white border-moss text-moss rounded-[10px]'
                    onClick={() => onDuplicateAppointment(appointment)}
                    disabled={
                      !appointment.atriaAppointment || !hasPermission(Permission.CREATE_APPOINTMENT)
                    }
                  />
                )}

                <Button
                  label='Delete'
                  icon='pi pi-trash'
                  iconPos='right'
                  size='small'
                  outlined
                  className='mt-2 bg-white border-rust text-rust rounded-[10px]'
                  onClick={() => onDeleteAppointment(appointment)}
                  disabled={
                    !appointment.atriaAppointment || !hasPermission(Permission.DELETE_APPOINTMENT)
                  }
                />

                {appointment.updatedBy && (
                  <div
                    className='mt-4 border-[1px] border-solid border-[#b2b2b2] text-neutral-500 min-h-[37px] rounded-[10px] bg-[#6d6d6d17] px-[14px] py-[7px] text-sm text-center cursor-pointer'
                    onClick={() => setShowAppointmentHistory(true)}
                  >
                    <span className='font-bold'>{updatedByLabel}</span>
                    <span className='ml-1 underline'>See history</span>
                  </div>
                )}
              </footer>
            </>
          ) : (
            <AppointmentHistory
              appointmentId={appointment.id}
              onReturn={() => setShowAppointmentHistory(false)}
            />
          )}

          <ConfirmDeleteDialog
            headerText={'Delete zoom link'}
            messageText={'Are you sure you want to delete the zoom link?'}
            visible={showConfirmConferenceLink}
            onCancel={() => setShowConfirmConferenceLink(false)}
            onHide={() => setShowConfirmConferenceLink(false)}
            onConfirm={deleteZoomLink}
          />
        </>
      )}
    </Sidebar>
  );
};
