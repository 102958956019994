import { ReactNode, useEffect, useCallback } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import { useLocation } from '@/contexts';
import { Region } from '@/@types';
import { SingOut } from '@/components/SignOut/SignOut';
import AtriaWord from '@/assets/atria-word-stone.svg';

type SchedulerLayoutProps = {
  headerComponent?: ReactNode;
  isLocationRequired?: boolean;
} & React.PropsWithChildren;

export const SchedulerLayout = ({
  children,
  headerComponent,
  isLocationRequired,
}: SchedulerLayoutProps) => {
  const {
    findAllLocations,
    locations: locationOptionsList,
    selectedRegion,
    changeLocation,
    setSelectedRegion,
  } = useLocation();

  useEffect(() => {
    findAllLocations();
  }, [findAllLocations]);

  return (
    <>
      {isLocationRequired ? (
        <>
          {!selectedRegion && locationOptionsList?.length > 0 ? (
            <LocationSelectionOverlay
              locations={locationOptionsList}
              setSelectedRegion={setSelectedRegion}
            />
          ) : (
            <>
              <SchedulerLayoutHeader
                locationOptions={locationOptionsList}
                regionSelected={selectedRegion?.id}
                changeRegion={changeLocation}
                headerComponent={headerComponent}
                shouldShowLocationSelection={true}
              />
              {children}
            </>
          )}
        </>
      ) : (
        <>
          <SchedulerLayoutHeader
            locationOptions={locationOptionsList}
            regionSelected={selectedRegion?.id}
            changeRegion={changeLocation}
            headerComponent={headerComponent}
            shouldShowLocationSelection={false}
          />
          {children}
        </>
      )}
    </>
  );
};

type LocationSelectionOverlayProps = {
  locations: Region[];
  setSelectedRegion: (location: Region | null) => void;
};

const LocationSelectionOverlay = ({
  locations,
  setSelectedRegion,
}: LocationSelectionOverlayProps) => {
  return (
    <div className='text-center content-center h-screen'>
      <h2 className='text-2xl font-bold mb-4'>Please choose a practice location:</h2>
      <div className='space-x-4'>
        {locations.map((locationOption) => (
          <button
            key={locationOption.id}
            className='min-w-[170px] px-10 py-12
       bg-primary-200 text-white rounded hover:bg-kelp'
            onClick={() => setSelectedRegion(locationOption)}
          >
            {locationOption.name}
          </button>
        ))}
      </div>
    </div>
  );
};

type SchedulerLayoutHeaderProps = {
  locationOptions?: Region[];
  regionSelected?: number;
  headerComponent?: ReactNode;
  shouldShowLocationSelection?: boolean;
  changeRegion?: (regionId: number) => void;
  onMenuToggle?: VoidFunction;
};

const SchedulerLayoutHeader = ({
  locationOptions = [],
  regionSelected,
  headerComponent,
  shouldShowLocationSelection = true,
  changeRegion,
}: SchedulerLayoutHeaderProps) => {
  const onLocationChange = useCallback(
    (event: DropdownChangeEvent) => {
      const regionId = event.target.value;
      if (changeRegion) {
        changeRegion(regionId);
      }
    },
    [changeRegion]
  );

  return (
    <div className='h-[64px] p-[var(--space-regular)] bg-[var(--fern)] flex justify-between items-center'>
      <div className='flex items-center'>
        <img src={AtriaWord} alt='Atria Logo' className='h-[32px] mx-4' />
        {shouldShowLocationSelection && !!locationOptions.length && (
          <Dropdown
            className='md:ml-5 ml-0 bg-transparent border hover:border-white h-11 flex items-center
          [&_*]:text-white [&_.p-dropdown-label]:pr-0 [&_*]:font-bold [&_*]:text-sm [&_.p-dropdown-trigger]:w-10'
            options={locationOptions}
            value={regionSelected}
            onChange={onLocationChange}
            dropdownIcon='pi pi-globe'
            optionLabel='name'
            optionValue='id'
            filter={false}
          />
        )}
      </div>
      {headerComponent}
      <SingOut />
    </div>
  );
};
