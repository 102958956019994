import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { usersServices } from '@/services';
import { useRooms } from '../hooks/useRooms';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useLocation } from './locationContext';
import { SAVED_VIEWS_PRESETS } from '@/data/data';
import { SchedulerView, SchedulerViewFilters, SchedulerViewType } from '@/@types';

type ContextType = {
  selectedView: SchedulerView | null;
  savedViews: SchedulerView[];
  findAllViewsForUser: () => void;
  saveViewForUser: (
    filters: SchedulerViewFilters,
    type?: SchedulerViewType
  ) => Promise<SchedulerView | undefined>;
  selectCurrentSchedulerView: (view: SchedulerView | null, forceUpdate?: boolean) => void;
  setAllSchedulerViews: (views: SchedulerView[]) => void;
  handleDeleteSchedulerView: (id: number) => void;
  handleUpdateSchedulerViewName: (id: number, name: string) => Promise<void>;
};

const Context = createContext<ContextType>({
  selectedView: null,
  savedViews: [],
  findAllViewsForUser: () => {},
  selectCurrentSchedulerView: () => {},
  saveViewForUser: async () => ({}) as SchedulerView,
  setAllSchedulerViews: () => {},
  handleDeleteSchedulerView: () => {},
  handleUpdateSchedulerViewName: async () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function SchedulerSavedViewContext({ children }: Props) {
  const [allSchedulerViews, setAllSchedulerViews] = useState<SchedulerView[]>([]);
  const [schedulerViews, setSchedulerViews] = useState<SchedulerView[]>([]);
  const [selectedView, setSelectedView] = useLocalStorage<SchedulerView | null>(
    'shedulerView',
    null
  );
  const { roomListAsResources } = useRooms();
  const { selectedRegion } = useLocation();

  const findAllViewsForUser = useCallback(async () => {
    const { data } = await usersServices.findAllSchedulerViewForUser();

    setAllSchedulerViews([...SAVED_VIEWS_PRESETS, ...data]);
  }, []);

  const saveViewForUser = useCallback(
    async (filters: SchedulerViewFilters, type: SchedulerViewType = SchedulerViewType.DAY) => {
      if (!selectedRegion?.id) return;
      const viewLength = schedulerViews.length;
      const lastViewNumber = Number(
        schedulerViews
          .sort((a, b) => b.id - a.id)
          .find(({ name }) => name.includes(`Saved View `))
          ?.name?.replace('Saved View ', '')
      );
      const numberToUse =
        lastViewNumber && lastViewNumber > viewLength ? lastViewNumber + 1 : viewLength + 1;

      const { data } = await usersServices.createSchedulerViewForUser({
        filters,
        name: `Saved View ${numberToUse}`,
        type,
        locationId: selectedRegion?.id,
      });
      setAllSchedulerViews((previous) => [...previous, data]);
      return data;
    },
    [schedulerViews, selectedRegion?.id]
  );

  const selectCurrentSchedulerView = useCallback(
    (view: SchedulerView | null, forceUpdate = false) => {
      if (!forceUpdate && (!allSchedulerViews || !allSchedulerViews.length)) return;
      setSelectedView(view);
    },
    [setSelectedView, allSchedulerViews]
  );

  const handleDeleteSchedulerView = useCallback(
    (id: number) => {
      if (selectedView?.id === id) {
        selectCurrentSchedulerView(null, true);
      }
      usersServices.deleteUserSchedulerView(id);
      setAllSchedulerViews((previous) => previous?.filter((view) => view.id !== id));
      setSchedulerViews((previous) => previous?.filter((view) => view.id !== id));
    },
    [selectedView, selectCurrentSchedulerView, setAllSchedulerViews]
  );

  const handleUpdateSchedulerViewName = useCallback(
    async (id: number, name: string) => {
      await usersServices.updateSchedulerView(id, { name });
      setAllSchedulerViews((previous) =>
        previous?.map((view) => ({ ...view, name: view.id === id ? name : view.name }))
      );
      setSchedulerViews((previous) =>
        previous?.map((view) => ({ ...view, name: view.id === id ? name : view.name }))
      );
      if (selectedView?.id === id) {
        selectCurrentSchedulerView({ ...selectedView, name });
      }
    },
    [selectCurrentSchedulerView, selectedView]
  );

  useEffect(() => {
    if (!allSchedulerViews || !allSchedulerViews.length) return;

    let currentViews = allSchedulerViews;
    if (selectedRegion) {
      currentViews = allSchedulerViews?.filter((view) => view.locationId === selectedRegion.id);
    }

    setSchedulerViews(currentViews || []);
  }, [selectedRegion, allSchedulerViews, roomListAsResources, selectedView, setSelectedView]);

  return (
    <Context.Provider
      value={{
        selectedView,
        savedViews: schedulerViews || [],
        setAllSchedulerViews,
        findAllViewsForUser,
        selectCurrentSchedulerView,
        saveViewForUser,
        handleDeleteSchedulerView,
        handleUpdateSchedulerViewName,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useSchedulerSavedViewContext = () => useContext(Context);
