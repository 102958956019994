import { Route } from 'react-router-dom';
import { AppointmentsContexts, SchedulerContexts } from '@/contexts';
import { AppointmentsPage, LoginCallbackPage, SchedulerPage, SchedulerWeeklyPage } from '@/pages';
import { SentryRoutes } from '@/providers';
import { RequiredAuth } from '.';

export function AppRoutes() {
  return (
    <SentryRoutes>
      <Route path='login/callback' element={<LoginCallbackPage />} />
      <Route path='/' element={<RequiredAuth />}>
        <Route path='' element={<SchedulerContexts />}>
          <Route path='' element={<SchedulerPage />} />
          <Route path='weekly' element={<SchedulerWeeklyPage />} />
        </Route>
        <Route path='' element={<AppointmentsContexts />}>
          <Route path='/appointments' element={<AppointmentsPage />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
}
