import { useState, memo, useCallback, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { DateTime } from 'luxon';

import { DateTimeHelper } from '@/helpers';
import styles from './DailyNotesDrawer.module.css';
import { DailyNotes } from '@/@types';

type DailyNotesDrawerProps = {
  visible: boolean;
  onHide: () => void;
  currentDate: Date;
  onDailyNotesSave: (newDailyNotes: string, id?: number) => void;
  selectedDailyNote: DailyNotes | null;
};

export const DailyNotesDrawer = ({
  visible,
  onHide,
  currentDate,
  onDailyNotesSave,
  selectedDailyNote,
}: DailyNotesDrawerProps) => {
  const luxonDate = DateTime.fromFormat(
    DateTimeHelper.returnOnlyDateYYYYmmDD(currentDate),
    'yyyy-MM-dd'
  ).toFormat('EEEE MMM dd');
  const [editorDailyNotes, setEditorDailyNotes] = useState('');

  const handleTextOnChange = useCallback((e: EditorTextChangeEvent) => {
    setEditorDailyNotes(e.htmlValue!);
  }, []);

  const onCancel = useCallback(async () => {
    setEditorDailyNotes('');
    onHide();
  }, [setEditorDailyNotes, onHide]);

  const onSave = useCallback(async () => {
    onDailyNotesSave(editorDailyNotes, selectedDailyNote?.id);
    onHide();
  }, [editorDailyNotes, onHide, onDailyNotesSave, selectedDailyNote]);

  const onClose = useCallback(async () => {
    onHide();
  }, [onHide]);

  useEffect(() => {
    setEditorDailyNotes(selectedDailyNote?.html || '');
  }, [selectedDailyNote]);

  return (
    <Sidebar
      visible={visible}
      position='right'
      onHide={onHide}
      className={styles.container}
      showCloseIcon={false}
    >
      <header className={styles.header}>
        <h1>Daily notes - {luxonDate}</h1>
        <Button icon='pi pi-times' rounded text aria-label='close' onClick={onClose} />
      </header>
      <main className={styles.content}>
        <Editor
          value={editorDailyNotes}
          onTextChange={handleTextOnChange}
          headerTemplate={<RenderHeader />}
          style={{ height: '150px' }}
        />
      </main>
      <footer className={styles.footer}>
        <>
          <Button
            label='Save'
            size='small'
            onClick={onSave}
            className={styles.saveButton}
            disabled={!editorDailyNotes}
          />
          <Button label='Cancel' outlined size='small' onClick={onCancel} />
        </>
      </footer>
    </Sidebar>
  );
};

const RenderHeader = memo(
  function RenderHeaderMemoized() {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
        <button className='ql-underline' aria-label='Underline'></button>
      </span>
    );
  },
  () => true
);
