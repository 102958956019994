import { useMemo } from 'react';
import TimeGrid from '@/components/Calendar/react-big-calendar/lib/TimeGrid';
import CustomTimeGrid from './CustomTimeGrid';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { DateTime } from 'luxon';

interface PopupOffset {
  x: number;
  y: number;
}

type MyWeekProps = {
  date: Date;

  events: any[];
  backgroundEvents: any[];
  resources?: any[];

  step?: number;
  timeslots?: number;
  range?: Date[];
  min?: Date;
  max?: Date;
  getNow: () => Date;

  scrollToTime?: Date;
  enableAutoScroll?: boolean;
  showMultiDayTimes?: boolean;

  rtl?: boolean;
  resizable?: boolean;
  width?: number;

  accessors: any;
  components: any;
  getters: any;
  localizer: any;

  allDayMaxRows?: number;

  selected?: any;
  selectable?: true | false | 'ignoreEvents';
  longPressThreshold?: number;

  onNavigate?: () => void;
  onSelectSlot: (slotInfo: any) => void;
  onSelectEnd?: () => void;
  onSelectStart?: () => void;
  onSelectEvent: (event: any) => void;
  onDoubleClickEvent: () => void;
  onKeyPressEvent?: () => void;
  onShowMore?: () => void;
  onDrillDown?: () => void;
  getDrilldownView: () => void;

  dayLayoutAlgorithm?: 'overlap' | 'no-overlap' | (() => void);

  showAllEvents?: boolean;
  doShowMoreDrillDown?: boolean;

  popup?: boolean;
  handleDragStart?: () => void;

  popupOffset?: number | PopupOffset;
};

export function CustomWeek({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  resources,
  ...props
}: MyWeekProps) {
  const currRange = useMemo(() => CustomWeek.range(date, { localizer }), [date, localizer]);
  return resources && resources?.length > 0 ? (
    <CustomTimeGrid
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      resources={resources}
      {...props}
    />
  ) : (
    <TimeGrid
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

CustomWeek.range = (date: Date, { localizer }: { localizer: any }) => {
  const start = DateTime.fromJSDate(date).startOf('week').toJSDate();
  const end = DateTime.fromJSDate(date).endOf('week').toJSDate();

  let current = start;
  const range = [];

  while (localizer.lte(current, end, 'day')) {
    range.push(current);
    current = localizer.add(current, 1, 'day');
  }

  return range;
};

CustomWeek.navigate = (date: Date, action: navigate, { localizer }: { localizer: any }) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -1, 'week');

    case navigate.NEXT:
      return localizer.add(date, 1, 'week');

    default:
      return date;
  }
};

CustomWeek.title = () => {
  return ``;
};
