import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import TimeGridHeader from '@/components/Calendar/react-big-calendar/lib/TimeGridHeader';
import DefaultHeader from '@/components/Calendar/react-big-calendar/lib/Header';
import ResourceHeader from '@/components/Calendar/react-big-calendar/lib/ResourceHeader';

class CustomTimeGridHeader extends TimeGridHeader {
  render() {
    const {
      width,
      rtl,
      resources,
      range,
      accessors,
      scrollRef,
      localizer,
      isOverflowing,
      components: {
        timeGutterHeader: TimeGutterHeader = DefaultHeader,
        resourceHeader: ResourceHeaderComponent = ResourceHeader,
        header: Header,
      },
    } = this.props;
    const style: {
      marginLeft?: string;
      marginRight?: string;
    } = {};
    if (isOverflowing) {
      style[rtl ? 'marginLeft' : 'marginRight'] = `${scrollbarSize() - 1}px`;
    }

    return (
      <div
        style={style}
        ref={scrollRef}
        className={clsx('rbc-time-header', isOverflowing && 'rbc-overflowing')}
      >
        <div
          className='rbc-label rbc-time-header-gutter'
          style={{ width, minWidth: width, maxWidth: width }}
        >
          {TimeGutterHeader && <TimeGutterHeader />}
        </div>
        {range.map((date, i) => {
          const label = localizer.format(date, 'dayFormat');
          const today = this.props.getNow();
          const { className, style: dayStyle } = this.props.getters.dayProp(date);
          return (
            <div className='rbc-time-header-content' key={i}>
              {date && (
                <div className='rbc-row rbc-row-resource' key={`resource_${i}`}>
                  <div className='rbc-header'>
                    <Header index={i} label={label} date={date} />
                  </div>
                </div>
              )}
              <div
                className={`rbc-row rbc-time-header-cell${
                  range.length <= 1 ? ' rbc-time-header-cell-single-day' : ''
                }`}
              >
                {resources?.map(([id, resource]: any, idx: any) => {
                  return (
                    <div
                      key={`resources-${i}-${id}-${idx}`}
                      style={{ ...dayStyle }}
                      className={clsx(
                        'rbc-header',
                        className,
                        localizer.isSameDate(date, today) && 'rbc-today'
                      )}
                    >
                      <ResourceHeaderComponent
                        index={idx}
                        label={accessors.resourceTitle(resource)}
                        resource={resource}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CustomTimeGridHeader;
