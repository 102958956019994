import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { FiltersProp } from '@/@types';
import { DateTimeHelper, ResourceHelper } from '@/helpers';

import { useProviders, useRooms, useLocalStorage } from '@/hooks';
import { useSchedulerSavedViewContext, useLocation as useSchedulerLocation } from '@/contexts';

function getDefaultDate() {
  const defaultURL = new URLSearchParams(window.location.search);
  const now = DateTime.now().toFormat('MM-dd-yyyy');
  return DateTimeHelper.returnTodayIfPastDate(defaultURL.get('date') || now);
}

const initialFiltersValuesLocalStorage: FiltersProp = {
  rooms: [],
  equipment: [],
  personnel: [],
  patients: [],
  locationOptionsList: [],
  date: getDefaultDate(),
  equipmentList: [],
  providersList: [],
  roomsList: [],
  cmos: [],
  cmosList: [],
  stateInitialized: false,
  isViewingDefaultView: false,
};

export function useFilters() {
  const { roomListAsResources: roomsList } = useRooms();
  const { locations: locationOptionsList } = useSchedulerLocation();
  const { equipmentList, providersList, cmosFilterList: cmosList } = useProviders();
  const { selectedView, selectCurrentSchedulerView } = useSchedulerSavedViewContext();

  const [filters, setFilters] = useLocalStorage<FiltersProp>(
    'filters',
    {
      ...initialFiltersValuesLocalStorage,
      locationOptionsList,
      equipmentList,
      providersList,
      cmosList,
      roomsList,
    },
    ({
      /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow*/
      patients,
      roomsList,
      equipmentList,
      providersList,
      cmosList,
      locationOptionsList,
      location,
      stateInitialized,
      ...state
      /* eslint-enable */
    }) => state as FiltersProp,
    (state) => {
      const date = DateTimeHelper.returnTodayIfPastDate(state.date);
      return {
        ...state,
        date,
        patients: [],
        stateInitialized: false,
        locationOptionsList,
        equipmentList,
        providersList,
        cmosList,
        roomsList,
      };
    }
  );

  const filterRef = useRef<FiltersProp>(filters);
  filterRef.current = filters;

  const setFilterItem = useCallback(
    (key: keyof FiltersProp, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    },
    [setFilters]
  );

  const setStaticFilterItem = useCallback(
    (key: keyof FiltersProp, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    },
    [setFilters]
  );

  const isViewingDefaultView = useMemo(() => {
    const filtersEmpty =
      filters.rooms.length === 0 &&
      filters.cmos.length === 0 &&
      filters.personnel.length === 0 &&
      filters.equipment.length === 0 &&
      filters.patients.length === 0;

    return filtersEmpty;
  }, [
    filters.cmos.length,
    filters.equipment.length,
    filters.patients.length,
    filters.personnel.length,
    filters.rooms.length,
  ]);

  useEffect(() => {
    if (selectedView) {
      setFilters((previous) => ({
        ...previous,
        rooms: ResourceHelper.filterResourceList(roomsList, selectedView.filters?.rooms),
        personnel: ResourceHelper.filterResourceList(
          providersList,
          selectedView.filters?.personnel
        ),
        equipment: ResourceHelper.filterResourceList(
          equipmentList,
          selectedView.filters?.equipment
        ),
        cmos: ResourceHelper.filterResourceList(cmosList, selectedView.filters?.cmos),
        patients: [],
        stateInitialized: true,
      }));
      return;
    }

    setFilters((previous) => ({
      ...previous,
      patients: [],
      stateInitialized: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView]);

  useEffect(() => {
    if (selectedView) {
      setTimeout(() => {
        const cmosListTemp = filterRef.current.cmos.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );
        const personnelListTemp = filterRef.current.personnel.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );
        const roomsListTemp = filterRef.current.rooms.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );
        const equipmentListTemp = filterRef.current.equipment.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );
        const isFilterStateEqualToSavedViewState =
          cmosListTemp.toString() === selectedView.filters?.cmos?.toString() &&
          personnelListTemp.toString() === selectedView.filters?.personnel?.toString() &&
          roomsListTemp.toString() === selectedView.filters?.rooms?.toString() &&
          equipmentListTemp.toString() === selectedView.filters?.equipment?.toString();
        if (!isFilterStateEqualToSavedViewState) {
          selectCurrentSchedulerView(null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView, filters]);

  return {
    filters,
    isViewingDefaultView,
    setFilterItem,
    setFilters,
    setStaticFilterItem,
  };
}
