import { useCallback, useMemo } from 'react';
import { ConfirmedTypes, PatientVisit } from '@/@types';
import { DEFAULT_GOOGLE_EVENT_TITLE } from '@/helpers';

type NewCalendarEventProps = {
  event: PatientVisit;
  onSelectBackgroundEvent: (event: PatientVisit) => void;
  hidePatientName?: boolean;
};

export const CalendarBackgroundEvent = ({
  event,
  hidePatientName,
  onSelectBackgroundEvent,
}: NewCalendarEventProps) => {
  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      const isShiftPressed = e.shiftKey;
      if (isShiftPressed) {
        e.preventDefault();
        e.stopPropagation();

        return onSelectBackgroundEvent(event);
      }
    },
    [event, onSelectBackgroundEvent]
  );

  const liason = useMemo(() => {
    return event.liaison ? event.liaison.name : '[no liaison]';
  }, [event.liaison]);

  const shouldDisplayConfirmedStatus = useMemo(() => {
    return event.appointments?.every(
      (appointment) => event.appointments?.[0].confirmed === appointment.confirmed
    );
  }, [event.appointments]);

  const confirmedStatus = useMemo(() => {
    const confirmed = event.appointments?.[0].confirmed;

    if (confirmed === ConfirmedTypes.FULLY_CONFIRMED) return 'check';

    return confirmed === ConfirmedTypes.SOFT_CONFIRMED ? 'circle-fill' : 'circle';
  }, [event.appointments]);

  const eventTitleInitials = useMemo(() => {
    return hidePatientName && event.title !== DEFAULT_GOOGLE_EVENT_TITLE
      ? event.title
          ?.split(' ')
          .map((word) => word[0])
          .join('')
      : event.title;
  }, [hidePatientName, event.title]);

  return (
    <div
      className='background-event relative h-full flex flex-col items-start justify-start text-off-black'
      onClick={handleOnClick}
    >
      <p className='flex w-full text-[length:var(--font-xs)] font-bold gap-1 items-center'>
        {shouldDisplayConfirmedStatus && <i className={`pi pi-${confirmedStatus} text-xs`}></i>}
        <span className='truncate'>{eventTitleInitials}</span>
        <span>{event.appointments?.[0].patientId}</span>
      </p>

      <p className='text-[length:var(--font-xs)] truncate w-full'>
        {liason}
        {event.cmo && <span className='italic'>, {event.cmo}</span>}
      </p>
    </div>
  );
};
