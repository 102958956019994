import { DailyNotes } from '@/@types';
import { api } from '@/api';

async function findDailyNotesByDate(date: string, locationId: number) {
  return api.get<DailyNotes[]>('/api/v1/daily-notes', { params: { date, locationId } });
}

async function createDailyNotes(date: string, html: string, locationId: number) {
  return api.post<DailyNotes>('/api/v1/daily-notes', { date, html, locationId });
}

async function updateDailyNote(id: number, html: string) {
  return api.patch<DailyNotes>('/api/v1/daily-notes/' + id, { html });
}

export const dailyNotesService = {
  findDailyNotesByDate,
  createDailyNotes,
  updateDailyNote,
};
