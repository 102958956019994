import { useToastContext } from '@/contexts';
import { googleService } from '@/services';
import { AutoComplete } from 'primereact/autocomplete';
import { useCallback, useState } from 'react';
import styles from '../AppointmentForm/AppointmentForm.module.css';

interface AddressOption {
  id?: string | null;
  label?: string | null;
}

type Props = {
  address?: (AddressOption | { label?: string; id?: string }) | null;
  setValue: (value: { label: string; id?: string }) => void;
};

export const SearchAddressAutocomplete = ({ address, setValue }: Props) => {
  const { toast } = useToastContext();
  const [addresses, setAddresses] = useState<AddressOption[]>([]);

  const getAddressOptions = useCallback(
    async (value: string) => {
      try {
        if (!value || value?.length < 3) return;

        const { data } = await googleService.getAddresses(value);

        setAddresses(
          data.suggestions.map((suggestion) => ({
            id: suggestion.placePrediction.placeId,
            label: suggestion.placePrediction.text.text,
          }))
        );
      } catch {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get addresses',
          life: 3000,
        });
      }
    },
    [toast]
  );

  return (
    <div className={styles.row}>
      <div className={styles.column + ` ml-0 gap-2`}>
        <div className='flex flex-col'>
          <label className={styles.label}>Address</label>

          <AutoComplete
            appendTo='self'
            field='label'
            forceSelection
            placeholder='Select an address'
            minLength={3}
            value={address}
            suggestions={addresses}
            completeMethod={(e) => getAddressOptions(e.query)}
            onChange={(e) => setValue(typeof e.value === 'object' ? e.value : { label: e.value })}
            className='break-words'
            inputStyle={{ width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};
