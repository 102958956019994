import {
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  Atria,
  ConfirmedTypes,
  SelectOption,
} from '@/@types';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { loader } from 'react-global-loader';
import { Controller, useForm } from 'react-hook-form';

import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import { Message } from 'primereact/message';
import { SearchPatientByNameOrId } from '..';
import styles from './AppointmentForm.module.css';
import {
  AppointmentFormWithoutTimeSchemaResolver,
  AppointmentWithoutTimeFormType,
} from './AppointmentFormSchema';
import { confirmedTypesOptions } from '@/utils';
import { ConfirmedStatus } from './ConfirmedStatus';

type EditMultipleAppointmentsFormProps = {
  roomsOptions: SelectOption[];
  personnelProviders: SelectOption[];
  nonPersonnelProviders: SelectOption[];
  appointments: AppointmentWithDate[] | AppointmentWithResourcesList[];
  appointmentTypes: AppointmentType[];
  onCancel: () => void;
  onUpdate: () => void;
};

export const EditMultipleAppointmentsForm = ({
  roomsOptions,
  personnelProviders,
  nonPersonnelProviders,
  appointments,
  appointmentTypes,
  onCancel,
  onUpdate,
}: EditMultipleAppointmentsFormProps) => {
  const { toast } = useToastContext();
  const { control, formState, watch, setValue, handleSubmit, reset } =
    useForm<AppointmentWithoutTimeFormType>({ resolver: AppointmentFormWithoutTimeSchemaResolver });
  const additionalProvidersSelected = watch('additionalProvidersSelected');
  const primaryProviderSelected = watch('primaryProviderSelected');
  const liaisonProviderSelected = watch('liaisonProviderSelected');
  const hasSomeAthenaAppointment = useMemo(
    () => appointments.some((appt) => !appt.atriaAppointment),
    [appointments]
  );

  const filteredProviders = useMemo(() => {
    return personnelProviders.filter((prov) => prov.id !== liaisonProviderSelected?.id);
  }, [liaisonProviderSelected?.id, personnelProviders]);

  const hasAthenaAppointmentAssociated = useMemo(() => {
    return appointments.some((appt) => !!appt.athenaAppointmentId);
  }, [appointments]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const removePreviousAppointmentTypeDefaultRoom = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType');

    if (!appointmentTypesSelected) {
      return;
    }
    const roomsSelected = watch('roomsSelected');
    const previousDefaultRoomsIds = new Set(
      appointmentTypesSelected.defaultRooms?.map(({ roomId }) => roomId) || []
    );
    const roomsSelectedWithoutPreviousDefaultRoom = roomsSelected?.filter(
      (room) => !previousDefaultRoomsIds.has(room.id)
    );
    setValue('roomsSelected', roomsSelectedWithoutPreviousDefaultRoom);
  }, [setValue, watch]);

  const addAppointmentTypeDefaultRoom = useCallback(
    (newType: AppointmentType) => {
      if (!newType || !newType.defaultRooms || newType.defaultRooms.length === 0) {
        return;
      }

      const roomsSelected = watch('roomsSelected');
      const defaultRoomsIds = new Set(newType?.defaultRooms?.map(({ roomId }) => roomId));
      const newSelectedRoomsIds = new Set([
        ...(roomsSelected?.map((room) => room.id) || []),
        ...defaultRoomsIds,
      ]);
      const newSelectedRooms = roomsOptions.filter((r) => newSelectedRoomsIds.has(r.id));
      return setValue('roomsSelected', newSelectedRooms);
    },
    [roomsOptions, setValue, watch]
  );

  const handleAppointmentTypeChange = useCallback(
    async (newType: AppointmentType) => {
      removePreviousAppointmentTypeDefaultRoom();
      addAppointmentTypeDefaultRoom(newType);
      setValue('appointmentType', newType);
    },
    [addAppointmentTypeDefaultRoom, removePreviousAppointmentTypeDefaultRoom, setValue]
  );

  const onSubmit = useCallback(
    async (data: AppointmentWithoutTimeFormType) => {
      loader.show();
      try {
        const providers = [
          ...(data.additionalProvidersSelected?.map((p) => ({
            id: p.id,
            name: p.label,
            type: 'ADDITIONAL',
          })) || []),
          ...(data.resourcesSelected?.map((p) => ({
            id: p.id,
            name: p.label,
            type: 'EQUIPMENT',
          })) || []),
        ];

        if (data.liaisonProviderSelected) {
          providers.push({
            id: data.liaisonProviderSelected.id,
            name: data.liaisonProviderSelected.label,
            type: 'LIAISON',
          });
        }

        const selectedRooms = data.roomsSelected || [];
        const confirmed = data.appointmentConfirmed;

        const newDate = new Date(data.date!);
        let appointmentRooms: SelectOption[] = [];

        const atria: Atria.UpdateMultipleAppointments.Atria[] = appointments
          .filter((a) => a.atriaAppointment)
          .map((appointment) => {
            appointmentRooms = [...selectedRooms];
            if (appointment.typeId && appointmentRooms.length) {
              const appointmentType = appointmentTypes.find(
                (type) => type.id === appointment.typeId
              );
              if (
                appointmentType &&
                appointmentType.defaultRooms &&
                appointmentType.defaultRooms.length > 0
              ) {
                const locationDefaultRoom = roomsOptions.find((room) =>
                  appointmentType.defaultRooms?.map((r) => r.roomId).includes(room.id)
                );

                if (
                  locationDefaultRoom &&
                  !selectedRooms.find((room) => room.id === locationDefaultRoom.id)
                ) {
                  appointmentRooms = [...appointmentRooms, locationDefaultRoom];
                }
              }
            }

            newDate.setHours(appointment.date.getHours());
            newDate.setMinutes(appointment.date.getMinutes());
            const apptResult: Atria.UpdateMultipleAppointments.Atria = {
              id: appointment.id,
              title: data.title || appointment.title,
              description: data.description,
              date: newDate.toJSON() || undefined,
              typeId: data.appointmentType?.id,
              type: data.appointmentType?.name,
              ...(data.primaryProviderSelected && {
                providerId: data.primaryProviderSelected.id,
                providerName: data.primaryProviderSelected.label,
              }),
              ...(data.patient && {
                patient: {
                  id: data.patient?.id,
                  firstName: data.patient?.firstName,
                  lastName: data.patient?.lastName,
                  firstNameUsed: data.patient?.firstNameUsed,
                  primaryProviderId: data.patient.primaryProviderId,
                },
              }),
              confirmed,
              providers,
              duration: appointment.duration,
            };

            if (selectedRooms.length) {
              apptResult.roomsIds = appointmentRooms.map((room) => room.id);
            }
            return apptResult;
          });

        const athenaAppointments = appointments
          .filter((a) => !a.atriaAppointment)
          .map(({ id }) => id);

        const athena: Atria.UpdateMultipleAppointments.Athena[] = athenaAppointments.map((id) => ({
          id,
          confirmed: true, // Making every athena appointment confirmed
          roomsIds: appointmentRooms.map((room) => room.id),
        }));

        await appointmentsService.updateMultipleAppointments({ atria, athena });

        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointments updated successfully',
          life: 3000,
        });
        reset();
        onUpdate();
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to update appointments',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [appointments, onUpdate, reset, toast, appointmentTypes, roomsOptions]
  );

  useEffect(() => {
    if (appointments.some((appt) => appt.confirmed === ConfirmedTypes.UNCONFIRMED)) {
      return setValue('appointmentConfirmed', ConfirmedTypes.UNCONFIRMED);
    }

    setValue(
      'appointmentConfirmed',
      appointments.every((appt) => appt.confirmed === ConfirmedTypes.FULLY_CONFIRMED)
        ? ConfirmedTypes.FULLY_CONFIRMED
        : ConfirmedTypes.SOFT_CONFIRMED
    );
  }, [appointments, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {hasAthenaAppointmentAssociated && (
        <div className={styles.row}>
          <Message
            className={styles.inlineColumn}
            severity='warn'
            text={`Telehealth appointment's synced to Athena Health must be edited one at a time.`}
          />
        </div>
      )}
      <div className={styles.row}>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
                [styles.columnDisabled]: hasSomeAthenaAppointment,
              })}
            >
              <label htmlFor='date' className={styles.label}>
                Date
              </label>
              <Calendar
                {...field}
                disabled={hasSomeAthenaAppointment}
                id='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                minDate={new Date()}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='title'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
                [styles.columnDisabled]: hasSomeAthenaAppointment,
              })}
            >
              <label
                htmlFor='title'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Title
              </label>
              <InputText
                {...field}
                id='title'
                placeholder='Title'
                value={field.value || ''}
                className={fieldState.error && styles.inputError}
                disabled={hasSomeAthenaAppointment}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='description'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
                [styles.columnDisabled]: hasSomeAthenaAppointment,
              })}
            >
              <label htmlFor='description' className={styles.label}>
                Description
              </label>
              <InputTextarea
                {...field}
                id='description'
                placeholder='Description'
                value={field.value || ''}
                disabled={hasSomeAthenaAppointment}
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <SearchPatientByNameOrId
          disabled={hasSomeAthenaAppointment}
          onSelect={(patient) => {
            if (!patient) return;
            setValue('patient', {
              id: patient.id,
              firstName: patient.firstName,
              lastName: patient.lastName,
              firstNameUsed: patient.firstNameUsed,
              patientName: patient.patientName,
              primaryProviderId: patient.primaryProvider?.id || null,
            });
          }}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='appointmentType'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
                [styles.columnDisabled]: hasSomeAthenaAppointment,
              })}
            >
              <label htmlFor='appointmentType' className={styles.label}>
                Appointment type
              </label>
              <Dropdown
                id='appointmentType'
                placeholder='Select an appointment type'
                filter
                optionLabel='name'
                disabled={hasSomeAthenaAppointment}
                options={appointmentTypes.sort((a, b) => a.name.localeCompare(b.name))}
                onChange={(e) => handleAppointmentTypeChange(e.value)}
                value={field.value}
                focusInputRef={field.ref}
                showClear
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='roomsSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                { [styles.columnError]: !!fieldState.error },
              ])}
            >
              <label htmlFor='roomsSelected' className={styles.label}>
                Rooms
              </label>
              <MultiSelect
                id='roomsSelected'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                className={classNames({ [styles.columnError]: !!fieldState.error })}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='primaryProviderSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
                [styles.columnDisabled]: hasSomeAthenaAppointment,
              })}
            >
              <label
                htmlFor='primaryProviderSelected'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Primary Provider
              </label>
              <Dropdown
                showClear
                disabled={hasSomeAthenaAppointment}
                id='primaryProviderSelected'
                placeholder='Select the primary provider'
                filter
                optionLabel='label'
                options={personnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
                className={styles.dropdownError}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='additionalProvidersSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                {
                  [styles.columnError]: !!fieldState.error,
                },
              ])}
            >
              <label htmlFor='additionalProvidersSelected' className={styles.label}>
                Additional Clinician
              </label>
              <MultiSelect
                id='additionalProvidersSelected'
                placeholder='Select additional staff'
                filter
                optionLabel='label'
                options={filteredProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='liaisonProviderSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='liaisonProviderSelected'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Liaison
              </label>
              <Dropdown
                showClear
                id='liaisonProviderSelected'
                placeholder='Select the liaison'
                filter
                optionLabel='label'
                options={
                  primaryProviderSelected
                    ? personnelProviders
                        .filter((p) => p.id !== primaryProviderSelected?.id)
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : personnelProviders.sort((a, b) => a.label.localeCompare(b.label))
                }
                onChange={(e) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
                className={styles.dropdownError}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='resourcesSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                {
                  [styles.columnError]: !!fieldState.error,
                },
              ])}
            >
              <label htmlFor='resourcesSelected' className={styles.label}>
                Resources
              </label>
              <MultiSelect
                id='resourcesSelected'
                placeholder='Select resources'
                filter
                optionLabel='label'
                options={nonPersonnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </div>
          )}
        />
      </div>

      <div className={styles.row}>
        <Controller
          control={control}
          name='appointmentConfirmed'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='appointmentConfirmed' className={styles.label}>
                Status:
              </label>

              <ConfirmedStatus
                value={field.value ?? ConfirmedTypes.UNCONFIRMED}
                onChange={(e) => field.onChange(e.value)}
                optionLabel='name'
                options={confirmedTypesOptions}
              />
            </div>
          )}
        />
      </div>

      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button
          label='Save'
          size='small'
          className='w-full'
          type='submit'
          disabled={Object.values(formState.errors).length > 0}
        />
        <Button
          label='Cancel'
          size='small'
          className='w-full'
          type='reset'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
