import { motion } from 'framer-motion';
import MobileDetect from 'mobile-detect';
import { loader } from 'react-global-loader';

import { SchedulerLayout } from '@/components/Layouts';
import { WeeklyCalendar } from '@/features/Calendar/WeeklyCalendar';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAppointments,
  useGoogleCalendar,
  useProviders,
  useRooms,
  useSchedulerSocket,
  useVisitTypeTemplates,
} from '@/hooks';
import { DateTime } from 'luxon';
import { parseAppointments } from '@/features/Calendar/utils';
import { AppointmentWithResourcesList, Atria, Origin, Resources } from '@/@types';
import { CalendarSettingsDrawer, WeeklyCalendarFilters } from '@/features';
import { useWeeklyCalendarFilters, useWeeklyCalendarSettings } from '@/features/Calendar/hooks';
import { useAppointmentActions } from '@/features/AppointmentActions';
import {
  useAppointmentTypeContext,
  useSchedulerSavedViewContext,
  useToastContext,
} from '@/contexts';
import { OrderColumnsHelper } from '@/helpers';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import { env } from '@/utils/constants';

export function SchedulerWeeklyPage() {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const navigate = useNavigate();

  const { getAppointmentsByRange } = useAppointments();
  const { onAppointmentDetailsClick, onAppointmentCreateClick } = useAppointmentActions();
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypeContext();
  const { googleCalendarAppointments } = useGoogleCalendar();
  const {
    cmosFilterList,
    providers: providersOptionsList,
    providersList,
    findAllProviders,
  } = useProviders();
  const { rooms: roomsOptionsList, suiteRoomsIds, findAllRooms } = useRooms();
  const { findAllViewsForUser } = useSchedulerSavedViewContext();
  const { toast } = useToastContext();
  const { visitTypeTemplates, findAllVisitTypeTemplates } = useVisitTypeTemplates();
  const { filters, setFilterItem } = useWeeklyCalendarFilters();
  const viewSettings = useWeeklyCalendarSettings();

  const [appointments, setAppointments] = useState<Atria.Appointment[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [resources, setResources] = useState<Resources[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);

  useSchedulerSocket(setAppointments, currentDate);

  const calendarAppointments = useMemo(() => {
    let result = parseAppointments(appointments);

    if (!viewSettings.showAthenaAppointments) {
      result = result.filter((i: any) => i?.origin !== Origin.Athena);
    }

    if (!viewSettings.showUnconfirmedAppointments) {
      result = result.filter(
        (i: AppointmentWithResourcesList) => i.confirmed && i.origin === Origin.Atria
      );
    }

    if (viewSettings.showGoogleCalendarEvents) {
      result = [...result, ...(googleCalendarAppointments as any)];
    }

    return result;
  }, [
    appointments,
    googleCalendarAppointments,
    viewSettings.showAthenaAppointments,
    viewSettings.showGoogleCalendarEvents,
    viewSettings.showUnconfirmedAppointments,
  ]);

  const findWeekAppointments = useCallback(
    async (date: Date) => {
      loader.show();
      try {
        const weekAppointments = await getAppointmentsByRange({
          startDate: DateTime.fromJSDate(date).startOf('day').toUTC().toString(),
          endDate: DateTime.fromJSDate(date).endOf('day').toUTC().toString(),
        });

        setAppointments(weekAppointments);
      } catch (error) {
        toast?.current?.show({
          className: 'text-xl opacity-100',
          severity: 'error',
          summary: 'Failed to load appointments',
          detail: `Reload the page. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
          life: 60000,
        });
      } finally {
        loader.hide();
      }
    },
    [getAppointmentsByRange, toast]
  );

  const handleAppointmentClick = useCallback(
    (event: AppointmentWithResourcesList) => {
      onAppointmentDetailsClick(event);
    },
    [onAppointmentDetailsClick]
  );

  const handleAppointmentCreateClick = useCallback(() => {
    onAppointmentCreateClick({
      start: currentDate,
    });
  }, [currentDate, onAppointmentCreateClick]);

  const handleOnResourcesChange = useCallback((values: Resources[]) => {
    return setResources(OrderColumnsHelper.order(values));
  }, []);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        getAppointmentTypes(),
        findAllVisitTypeTemplates(),
        findAllViewsForUser(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 60000,
      });
    }
  }, [
    findAllProviders,
    findAllRooms,
    findAllViewsForUser,
    findAllVisitTypeTemplates,
    getAppointmentTypes,
    toast,
  ]);

  useEffect(() => {
    findWeekAppointments(currentDate);
  }, [currentDate, findWeekAppointments]);

  useEffect(() => {
    handleOnResourcesChange([...filters.personnel, ...filters.cmos]);
  }, [filters.personnel, filters.cmos, handleOnResourcesChange]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <SchedulerLayout>
      <div className='h-full p-4'>
        {env.APP_FEATURE_FLAGS.IS_TO_SHOW_WEEKLY_CALENDAR && (
          <motion.div
            variants={{
              open: { x: 0 },
              closed: { x: -296 },
            }}
            transition={{ damping: 40 }}
            animate={isMobile || (!isMobile && showFilters) ? 'open' : 'closed'}
            className='mb-6 md:mb-2 w-full md:max-w-[280px] flex gap-2 items-center justify-center md:justify-start relative md:absolute '
          >
            <button
              className='text-lg cursor-pointer text-product-gray-200 hover:underline'
              onClick={() => {
                navigate('/');
              }}
            >
              Daily View
            </button>
            /<button className='text-lg cursor-pointer text-fern font-medium'>WeeklyView</button>
          </motion.div>
        )}

        <motion.aside
          variants={{
            open: { x: 0 },
            closed: { x: -300 },
          }}
          transition={{ damping: 40 }}
          animate={showFilters ? 'open' : 'closed'}
          className='h-full absolute w-screen max-w-[280px] flex flex-col top-[160px]'
        >
          {isMobile ? (
            <Sidebar visible={showFilters} onHide={() => setShowFilters(false)} position='left'>
              <WeeklyCalendarFilters
                cmosFilterList={cmosFilterList}
                currentDate={currentDate}
                filters={filters}
                onCurrentDateChange={(date: Date) => setCurrentDate(date)}
                onResourcesChange={handleOnResourcesChange}
                providersFilterList={providersList}
                setFilterItem={setFilterItem}
              />
            </Sidebar>
          ) : (
            <WeeklyCalendarFilters
              cmosFilterList={cmosFilterList}
              currentDate={currentDate}
              filters={filters}
              onCurrentDateChange={(date: Date) => setCurrentDate(date)}
              onResourcesChange={handleOnResourcesChange}
              providersFilterList={providersList}
              setFilterItem={setFilterItem}
            />
          )}
        </motion.aside>

        <motion.main
          initial={{ marginLeft: isMobile ? 0 : '288px' }}
          variants={{
            margin: { marginLeft: isMobile ? 0 : '288px' },
            noMargin: { marginLeft: '0px' },
          }}
          transition={{ damping: isMobile ? 0 : 40 }}
          animate={showFilters ? 'margin' : 'noMargin'}
          className='h-full'
        >
          <WeeklyCalendar
            appointmentTypesList={appointmentTypesOptionsList}
            currentDate={currentDate}
            events={calendarAppointments}
            providersOptionsList={providersOptionsList}
            onAppointmentClick={handleAppointmentClick}
            onCalendarViewSettingsClick={() => setShowCalendarSettings((prev) => !prev)}
            onCurrentDateChange={setCurrentDate}
            onNewAppointmentClick={handleAppointmentCreateClick}
            onToggleFiltersClick={() => setShowFilters((prev) => !prev)}
            resources={resources}
            roomsOptionsList={roomsOptionsList}
            showFullCalendar={viewSettings.showFullCalendar}
            suiteRoomsIds={suiteRoomsIds}
            visitTypeTemplates={visitTypeTemplates}
          />

          <CalendarSettingsDrawer
            isVisible={showCalendarSettings}
            onHide={() => setShowCalendarSettings(false)}
            {...viewSettings}
          />
        </motion.main>
      </div>
    </SchedulerLayout>
  );
}
