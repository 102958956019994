import { SelectButton, SelectButtonProps } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import './ConfirmedStatus.css';

export const ConfirmedStatus = (props: SelectButtonProps) => {
  return (
    <div>
      <SelectButton {...props} className={classNames(props.className, 'confirmed-status-select')} />

      <p className='text-[#757575] text-[11px] font-medium leading-none mt-[11px] ml-1'>
        * Tentative and confirmed appts will appear in the member’s portal
      </p>
    </div>
  );
};
