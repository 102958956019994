import { ConfirmedTypes, confirmedTypes } from '@/@types';
import { RegexHelper } from '@/helpers';
import * as z from 'zod';

export const SelectOptionSchema = z.object({
  id: z.number(),
  label: z.string(),
});

export const AppointmentTimeSchema = z.object({
  start: z.string().refine((value) => RegexHelper.TIME.test(value), {
    message: 'Invalid format.',
  }),
  end: z.string().refine((value) => RegexHelper.TIME.test(value), {
    message: 'Invalid format.',
  }),
});

export const AppointmentFormBaseSchema = z.object({
  parentId: z.number().optional(),
  date: z.preprocess(
    (value: unknown) => {
      if (value) {
        return Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(new Date(String(value)));
      }
      return undefined;
    },
    z.string({
      required_error: 'Please select a date',
    })
  ),
  title: z.string({ required_error: 'Please, enter a title' }).min(1, 'Please, enter a title'),
  description: z.string().nullish(),
  atriaAppointment: z.boolean(),
  primaryProviderSelected: SelectOptionSchema.nullish(),
  liaisonProviderSelected: SelectOptionSchema.nullish(),
  address: z
    .object({
      id: z.string().nullable().optional(),
      label: z.string().nullable().optional(),
      address: z.string().nullable().optional(),
      addressDetails: z.string().nullable().optional(),
      addressNumber: z.string().nullable().optional(),
      city: z.string().nullable().optional(),
      state: z.string().nullable().optional(),
      zipCode: z.string().nullable().optional(),
      country: z.string().nullable().optional(),
      latitude: z.string().nullable().optional(),
      longitude: z.string().nullable().optional(),
    })
    .nullish(),
  resourcesSelected: z.array(SelectOptionSchema).nullish(),
  additionalProvidersSelected: z.array(SelectOptionSchema).nullish(),
  roomsSelected: z.array(SelectOptionSchema),
  patient: z
    .object({
      patientName: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      firstNameUsed: z.string().nullish(),
      id: z.number(),
      primaryProviderId: z.number().nullish(),
    })
    .nullish(),
  appointmentType: z
    .object({
      id: z.number(),
      name: z.string(),
      duration: z.number().optional(),
      defaultRooms: z
        .array(
          z.object({
            id: z.number(),
            typeId: z.number(),
            roomId: z.number(),
            restricted: z.boolean(),
          })
        )
        .optional(),
    })
    .nullish(),
  appointmentConfirmed: z.enum(confirmedTypes).default(ConfirmedTypes.UNCONFIRMED),
  athenaDepartment: z
    .object({
      departmentId: z.number(),
      name: z.string(),
    })
    .optional(),
});
