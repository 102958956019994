import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { AppointmentWithDate, Origin } from '@/@types';
import styles from './CalendarEvent.module.css';

type CalendarEventProps = {
  appointment: AppointmentWithDate;
};

export const CalendarEvent = ({ appointment }: CalendarEventProps) => {
  const formattedStartAndEndTime = useMemo(() => {
    const start = DateTime.fromJSDate(appointment.date);
    const end = DateTime.fromJSDate(appointment.end);
    const formattedStart =
      start.toFormat('mm') === '00' ? start.toFormat('h') : start.toFormat('h:mm');
    const formattedEnd = (
      end.toFormat('mm') === '00' ? end.toFormat('ha') : end.toFormat('h:mma')
    ).toLowerCase();

    return `${formattedStart} - ${formattedEnd}`;
  }, [appointment]);

  const eventClasses = useMemo(() => {
    const classes = classNames(styles.calendarEvent, {
      [styles.eventXxs]: appointment.duration <= 15,
      [styles.eventXs]: appointment.duration > 15 && appointment.duration <= 30,
    });
    return classes;
  }, [appointment.duration]);

  const patientName = useMemo(() => {
    const names = appointment.patientName?.split(' ');

    if (!names) return '';

    if (names.length > 1) {
      return `${names[0][0]}${names[names.length - 1][0]} ${appointment.patientId}`;
    }

    return names[0];
  }, [appointment]);

  return (
    <div className={classNames(eventClasses, 'relative')}>
      <p
        className={classNames([
          styles.eventTitle,
          appointment.duration > 15 && 'whitespace-nowrap overflow-hidden overflow-ellipsis',
        ])}
      >
        {appointment.title}
      </p>
      <p className={styles.eventDuration}>{formattedStartAndEndTime}</p>
      {appointment.origin != Origin.Google && (
        <div
          className={classNames([
            'font-normal text-[length:var(--font-sm)]',
            styles.eventExtraContent,
          ])}
        >
          {appointment.patientName && (
            <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{patientName}</p>
          )}
          {appointment.provider && appointment.provider.label && (
            <p>{appointment.provider.label}</p>
          )}
          {appointment.rooms?.map((room) => <p key={room.id}>{room.label}</p>)}
        </div>
      )}
      <div
        className={classNames([
          'font-normal text-[length:var(--font-sm)]',
          styles.eventExtraContent,
        ])}
      ></div>
    </div>
  );
};
