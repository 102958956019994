import { HttpStatusCode } from 'axios';
import { useCallback } from 'react';
import { useToastContext } from '@/contexts';
import { dailyNotesService } from '@/services';
import { DateTimeHelper } from '@/helpers';
import { DailyNotes } from '@/@types';

export function useDailyNotes() {
  const { toast: ref } = useToastContext();

  const findDailyNotesByDate = useCallback(
    async (date: Date, locationId: number): Promise<DailyNotes[]> => {
      try {
        const { data } = await dailyNotesService.findDailyNotesByDate(
          DateTimeHelper.returnOnlyDateYYYYmmDD(date),
          locationId
        );

        return data;
      } catch {
        return [];
      }
    },
    []
  );

  const createDailyNotes = useCallback(
    async (date: Date, html: string, locationId: number) => {
      try {
        const { status, data } = await dailyNotesService.createDailyNotes(
          DateTimeHelper.returnOnlyDateYYYYmmDD(date),
          html,
          locationId
        );

        if (status !== HttpStatusCode.Ok) {
          return null;
        }

        ref?.current.show({
          severity: 'success',
          summary: 'Saved',
          detail: 'Daily note saved successfully',
          life: 1500,
        });

        return data;
      } catch {
        return null;
      }
    },
    [ref]
  );

  const updateDailyNote = useCallback(
    async (id: number, html: string) => {
      try {
        const { status, data } = await dailyNotesService.updateDailyNote(id, html);

        if (status !== HttpStatusCode.Ok) {
          return null;
        }

        ref?.current.show({
          severity: 'success',
          summary: 'Saved',
          detail: 'Daily note saved successfully',
          life: 1500,
        });

        return data;
      } catch {
        return null;
      }
    },
    [ref]
  );

  return {
    findDailyNotesByDate,
    createDailyNotes,
    updateDailyNote,
  };
}
