import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import { inRange, sortEvents } from 'react-big-calendar/lib/utils/eventLevels';
import TimeGrid from '@/components/Calendar/react-big-calendar/lib/TimeGrid';
import DayColumn from '@/components/Calendar/react-big-calendar/lib/DayColumn';
import CustomTimeGridHeader from './CustomTimeGridHeader';
import TimeGutter from '@/components/Calendar/react-big-calendar/lib/TimeGutter';

class CustomTimeGrid extends TimeGrid {
  renderEvents(range: any[], events: any[], backgroundEvents: any[], now: Date) {
    const { min, max, components, accessors, localizer, dayLayoutAlgorithm, step } = this.props;

    const resources = this.memoizedResources(this.props.resources, accessors);
    const groupedEvents = resources.groupEvents(events);
    const groupedBackgroundEvents = resources.groupEvents(backgroundEvents);

    return range.map((date, jj) => {
      return resources.map(([id, resource]: any, i: number) => {
        const daysEvents = (groupedEvents.get(id) || []).filter((event: any) =>
          localizer.inRange(date, accessors.start(event), accessors.end(event), 'day')
        );
        const daysBackgroundEvents = (groupedBackgroundEvents.get(id) || []).filter((event: any) =>
          localizer.inRange(date, accessors.start(event), accessors.end(event), 'day')
        );

        return (
          <DayColumn
            {...this.props}
            localizer={localizer}
            min={localizer.merge(date, min)}
            max={localizer.merge(date, max)}
            resource={resource && id}
            components={components}
            isNow={localizer.isSameDate(date, now)}
            key={jj + '-' + i}
            date={date}
            events={daysEvents}
            backgroundEvents={daysBackgroundEvents}
            dayLayoutAlgorithm={dayLayoutAlgorithm}
            step={step ? step : 30}
          />
        );
      });
    });
  }
  render() {
    const {
      range,
      events,
      backgroundEvents,
      selected,
      getNow,
      resources,
      components,
      accessors,
      getters,
      localizer,
      min,
      max,
      showMultiDayTimes,
      longPressThreshold,
      resizable,
      rtl,
    } = this.props;
    let { width } = this.props;

    width = width || this.state.gutterWidth;

    const start = range[0],
      end = range[range.length - 1];

    const allDayEvents: any[] = [],
      rangeEvents: any[] = [],
      rangeBackgroundEvents: any[] = [];

    events.forEach((event) => {
      if (inRange(event, start, end, accessors, localizer)) {
        const eStart = accessors.start(event),
          eEnd = accessors.end(event);

        if (
          accessors.allDay(event) ||
          localizer.startAndEndAreDateOnly(eStart, eEnd) ||
          (!showMultiDayTimes && !localizer.isSameDate(eStart, eEnd))
        ) {
          allDayEvents.push(event);
        } else {
          rangeEvents.push(event);
        }
      }
    });

    backgroundEvents.forEach((event) => {
      if (inRange(event, start, end, accessors, localizer)) {
        rangeBackgroundEvents.push(event);
      }
    });

    allDayEvents.sort((a, b) => sortEvents(a, b, accessors, localizer));

    return (
      <div
        className={clsx('rbc-time-view', resources && 'rbc-time-view-resources')}
        ref={this.containerRef}
      >
        <CustomTimeGridHeader
          range={range}
          events={allDayEvents}
          width={width}
          rtl={rtl}
          getNow={getNow}
          localizer={localizer}
          selected={selected}
          allDayMaxRows={this.props.showAllEvents ? Infinity : this.props.allDayMaxRows ?? Infinity}
          resources={this.memoizedResources(resources, accessors)}
          selectable={this.props.selectable}
          accessors={accessors}
          getters={getters}
          components={components}
          scrollRef={this.scrollRef}
          isOverflowing={this.state.isOverflowing}
          longPressThreshold={longPressThreshold}
          onSelectSlot={this.handleSelectAllDaySlot}
          onSelectEvent={this.handleSelectEvent}
          onShowMore={this.handleShowMore}
          onDoubleClickEvent={this.props.onDoubleClickEvent}
          onKeyPressEvent={this.props.onKeyPressEvent}
          onDrillDown={this.props.onDrillDown}
          getDrilldownView={this.props.getDrilldownView}
          resizable={resizable}
        />
        {this.props.popup && this.renderOverlay()}
        <div ref={this.contentRef} className='rbc-time-content' onScroll={this.handleScroll}>
          <TimeGutter
            ref={this.gutterRef}
            localizer={localizer}
            min={localizer.merge(start, min)}
            max={localizer.merge(start, max)}
            step={this.props.step || 30}
            getNow={this.props.getNow}
            timeslots={this.props.timeslots || 2}
            components={components}
            getters={getters}
          />
          {this.renderEvents(range, rangeEvents, rangeBackgroundEvents, getNow())}
        </div>
      </div>
    );
  }
}

export default CustomTimeGrid;
